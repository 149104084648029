import './App.css';


function App() {
  return (
    <div className="App">
      <h1>Lightspeedsounds!</h1>
      <p>Why the HECK not!?
      
      </p>
      <img src={'/letsgetrocking.gif'} alt="Rocking" />
    </div>
  );
}

export default App;
